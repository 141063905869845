import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuracion } from '../config/configuracion';
import { EmpleadoModel } from '../modelos/empleado.model';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {

  constructor(
    private http: HttpClient,
  ) { }

  DatosBasicosConCiudad(id: string): Observable<EmpleadoModel> {
    return this.http.get<EmpleadoModel>(`${Configuracion.urlbackend}/empleados/${id}?filter={
      "fields":["id","nombres","apellidos","foto","fotoThumb","ciudadId","documento","celular"],
      "include":[
        {
          "relation":"ciudad",
          "scope":{
            "fields":["id","nombre"]            
          }
        }
      ]
    }`);
  }

  CiudadId(id: string): Observable<EmpleadoModel> {
    return this.http.get<EmpleadoModel>(`${Configuracion.urlbackend}/empleados/${id}?filter={
      "fields":["id","ciudadId"]
    }`);
  }

  DatosBasicos(id: string): Observable<EmpleadoModel> {
    return this.http.get<EmpleadoModel>(`${Configuracion.urlbackend}/empleados/${id}?filter={
      "fields":["id","nombres","apellidos","fotoThumb"],
      "include":["ciudad"]
    }`);
  }

  ActualizarEmpleado(empleado: EmpleadoModel): Observable<EmpleadoModel> {
    return this.http.patch<EmpleadoModel>(`${Configuracion.urlbackend}/empleados/${empleado.id}`, empleado)
  }

  ObtenerEmpleados(): Observable<EmpleadoModel[]> {
    return this.http.get<EmpleadoModel[]>(`${Configuracion.urlbackend}/empleados?filter={
      "fields":["id","nombres","apellidos","documento","celular","fotoThumb","ciudadId"],
      "include":[
        {
          "relation":"ciudad",
          "scope":{
            "fields":["id","nombre"]            
          }
        }
      ]
    }`);
  }

}
