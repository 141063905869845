import { environment } from 'src/environments/environment';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Configuracion {
    export const urlbackend = environment.urlbackend;
    export const urlweb = environment.urldownload;
    export const sexos = ['Masculino','Femenino','Otro'];
    export const jornadas = ['Mañana','Tarde'];
    export const lugaresCita = ['Sede','Domicilio'];
    //export const ciudades = ["Santa Marta","Manizales"];
    export const departamentos = ['Administración','Atención al Cliente','Comercial','Marketing','Sistemas'];
    // eslint-disable-next-line max-len
    export const cargos = ['Administrador','Auxiliar','Backoffice','Contador','Director Administrativo','Director Comercial','Domiciliario','Gerente Administrativo','Gerente Comercial','Gerente Marketing','Vendedor','Veterinario'];
    export const estadosProducto = ['disponible','no disponible','descontinuado'];

    //export const estadosCitas = ["POR AGENDAR","AGENDADA","CANCELADA","FINALIZADA"];
    //================= IMPORTANTE MANTENER ESTAS DOS IGUAL LOS PRIMEROS DOS ELEMENTOS =======================//
    export const estadosCitas = ['POR AGENDAR','AGENDADA','ACUDIÓ','CON PENDIENTES','CANCELADA','FINALIZADA'];//
    export const estadosNuevaCita = ['POR AGENDAR','AGENDADA'];                                               //
    //========================================================================================================//

    //------------------ PYP en tercer lugar ----------------
    export const tipoCitas = ['consulta','servicio','PYP'];
    export const estadosPedido = ['EN PROCESO','DESPACHADO','ENTREGADO','CANCELADO'];
    export const descuentoPorReferido = 0.1;

    //-------------------------------------------------------
    // eslint-disable-next-line max-len
    export const motivosInactivo = ['Inconformidad','La mascota cambió de dueño','No tiene dinero','No volvieron a contestar','Plan vencido','Se fue de la ciudad','Otro motivo'];
    // eslint-disable-next-line max-len
    export const motivoRescindido = ['Cambio de ciudad','Desempleo','Dueño no quiere el servicio','Inconformidad','Mascota Fallecida','No volvió a contestar','Otro'];

    //Estados cliente pendiente, activo, inactivo, rescindido
    export const estadosCliente = ['pendiente','afiliado','no afiliado','inactivo'];
    export const estadosMascota = ['pendiente','activo','inactivo','no afiliado','rescindido'];

    //Tipos de factura
    //pagoplan, servicio, pedido, afiliacion
}
