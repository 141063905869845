import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SeguridadService } from '../services/seguridad.service';

@Injectable({
  providedIn: 'root'
})
export class IdentificadoGuard implements CanActivate {
  constructor(
    private seguridadService: SeguridadService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem('datosSesion') && this.seguridadService.obtenerInfoSesion().estaIdentificado) {
      if (this.seguridadService.obtenerRolUsuario() === 'cliente') {
        this.router.navigate(['/cliente']);
      } else {
        this.router.navigate(['/empleado']);
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
