import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuracion } from '../config/configuracion';
import { ConfiguracionModel } from '../modelos/configuracion.model';
import { IdentificarModel } from '../modelos/identificar.model';

@Injectable({
  providedIn: 'root'
})
export class SeguridadService {

  public version = 0.9; 

  datosusuarioensesion = new BehaviorSubject<IdentificarModel>(new IdentificarModel());

  constructor(
    private http: HttpClient,
    ) {
    this.verificarSesionActual();
  }

  verificarSesionActual() {
    const datos = this.obtenerInfoSesion();
    if (datos) {
      this.refrescarDatosSesion(datos);
    }
  }

  refrescarDatosSesion(datos: IdentificarModel) {
    this.datosusuarioensesion.next(datos);
  }

  obtenerDatosUsuarioEnSesion() {
    return this.datosusuarioensesion.asObservable();
  }

  login(usuario: string, password: string): Observable<IdentificarModel> {
    return this.http.post<IdentificarModel>(`${Configuracion.urlbackend}/login/app`, {
      usuario: usuario,
      password: password
    },
      {
        headers: new HttpHeaders({})
      }
    );
  }

  configuracionPorNombre(nombre: string):Observable<ConfiguracionModel>{
    return this.http.get<ConfiguracionModel>(`${Configuracion.urlbackend}/configuracion/nombre/${nombre}`)
  }

  validarEmail(hash: string): Observable<boolean>{
    return this.http.get<boolean>(`${Configuracion.urlbackend}/validar-email/${hash}`);
  }

  existeHash(hash: string): Observable<boolean>{
    return this.http.get<boolean>(`${Configuracion.urlbackend}/existehash/${hash}`);
  }

  almacenarSesion(datos: IdentificarModel) {
    datos.estaIdentificado = true;
    const stringDatos = JSON.stringify(datos);
    localStorage.setItem('datosSesion', stringDatos);
    this.refrescarDatosSesion(datos);
  }

  eliminarInfoSesion() {
    localStorage.removeItem('datosSesion');
    this.refrescarDatosSesion(new IdentificarModel());

  }

  seHaIniciadoSesion() {
    const datosString = localStorage.getItem('datosSesion');
    return datosString;
  }

  obtenerToken(){
    const datosString = localStorage.getItem('datosSesion');
    if(datosString){
      const datos = JSON.parse(datosString);
      return datos.tk;
    }else{
      return '';
    }
  }

  obtenerInfoSesion() {
    const datosString = localStorage.getItem('datosSesion');
    if (datosString) {
      const datos = JSON.parse(datosString);
      return datos;
    } else {
      return null;
    }
  }

  obtenerIdPersona(){
    return this.obtenerInfoSesion().datos.personaId;
  }

  obtenerIdUsuario(){
    return this.obtenerInfoSesion().datos.id;
  }

  obtenerRolUsuario(){
    return (this.obtenerInfoSesion())?this.obtenerInfoSesion().datos.rol:null;
  }
  
  obtenerPersonaLogin(){
    return this.obtenerInfoSesion().persona;
  }
  
}
