import { EventEmitter, Injectable } from '@angular/core';
import { AliadoModel } from '../modelos/aliados.model';
import { AvisoModel } from '../modelos/aviso.model';
import { CiudadModel } from '../modelos/ciudad.model';
import { ClienteModel } from '../modelos/cliente.model';
import { EmpleadoModel } from '../modelos/empleado.model';
import { MascotaModel } from '../modelos/mascota.model';
import { MetodoPagoModel } from '../modelos/metodopago.model';
import { PedidoModel } from '../modelos/pedido.model';
import { PeriodoCarenciaModel } from '../modelos/periodocarencia.model';
import { PersonaModel } from '../modelos/persona.model';
import { PlanModel } from '../modelos/plan.model';
import { ProductoModel } from '../modelos/producto.model';
import { ProductoProveedorModel } from '../modelos/productoproveedor.model';
import { ProveedorModel } from '../modelos/proveedor.model';
import { ReferidoModel } from '../modelos/referido.model';
import { ServicioModel } from '../modelos/servicio.model';
import { UnidadNegocioModel } from '../modelos/unidadnegocio.model';
import { UsuarioModel } from '../modelos/usuario.model';
import { VeterinariaModel } from '../modelos/veterinaria.model';
import { VisitaPypModel } from '../modelos/visitapyp.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  aliadoActual: AliadoModel = {};
  clienteActual: ClienteModel = {};
  empleadoActual: EmpleadoModel = {};
  mascotaActual: MascotaModel = {};
  productoActual: ProductoModel = {};
  servicioActual: ServicioModel = {};
  proveedorActual: ProveedorModel = {};
  servicioProveedorActual: ProductoProveedorModel = {};
  productoProveedorActual: ProductoProveedorModel = {};
  citaActual: any = {};
  ciudadActual: CiudadModel = {};
  planActual: PlanModel = {};
  metodoPagoActual: MetodoPagoModel = {};
  periodoCarenciaActual: PeriodoCarenciaModel = {};
  redirigirBuscador: string = "";
  pedidoActual: PedidoModel = {};
  pagoplanActual: any = {};
  pedidosDomicilio: any;
  ubicacion: any;
  itemMapa: any;
  ubicaciones: any[] = [];
  referidos: ReferidoModel[] = [];
  visitaPYPActual: VisitaPypModel = {};
  detalleservicioActual: any = {};
  consultaActual: string = "";
  imagenActual: string = "";
  proximos: any[] = [];
  nuevos: any[] = [];
  extractos: any[] = [];
  tituloModal: string = "";
  dataGraficoGastos: any = {};
  dataGrafico: any[] = [];
  idusuario: string = "";
  idx: string = "";
  listaMascotas: any = [];
  listaPagos: any = [];
  usuariologin: UsuarioModel = {};
  datosImagenAhorro: any = {};
  avisoActual: AvisoModel = {};
  veterinariaActual: VeterinariaModel = {};

  listaCiudades$ = new EventEmitter<CiudadModel[]>();
  listaUnidades$ = new EventEmitter<UnidadNegocioModel[]>();
  listaClientes$ = new EventEmitter<ClienteModel[]>();
  listaEmpleados$ = new EventEmitter<EmpleadoModel[]>();
  listaProductos$ = new EventEmitter<ProductoModel[]>();
  listaServicios$ = new EventEmitter<ServicioModel[]>();
  listaProveedores$ = new EventEmitter<ProveedorModel[]>();
  listaCitas = new EventEmitter<any[]>();
  listaPlanes = new EventEmitter<any[]>();
  listaMetodosPago = new EventEmitter<any[]>();
  algocambio = new EventEmitter<boolean>();
  candismiss = new EventEmitter<boolean>();
  personaLogin = new EventEmitter<PersonaModel>();


  ubicacionCliente: any = {};

  ubicacionOficina: any = {
    lat: 11.24079,
    lng: -74.19904
  }

  constructor() { }

  limpiar() {
    this.empleadoActual = {};
    this.ubicacionCliente = {};
    this.mascotaActual = {}
    this.citaActual = {};
    this.redirigirBuscador = "";
  }

  diasHastaHoy(fecha: Date) {
    let hoy = +new Date();
    let proximoPago = +new Date(fecha);
    let diff = (proximoPago - hoy) / 86400000;
    return diff
  }

  calcularEdad(fechaN: Date) {
    let hoy = new Date()
    let fnac = new Date(fechaN);
    let edad = hoy.getFullYear() - fnac.getFullYear()
    let m = hoy.getMonth() - fnac.getMonth()
    if (m < 0 || (m === 0 && hoy.getDate() < fnac.getDate())) {
      edad--
    }
    if (edad === 0) {
      let meses = Math.trunc(((+hoy - +fnac) / 86400000) / 30)
      return meses + " meses";
    } else {
      return (edad === 1) ? edad + " año" : edad + " años";
    }
  }

  randomNumber() {
    return (Math.floor(Math.random() * (100000 - 100 + 1) + 100));
  }

  textoCorto(texto: string, lng: number) {
    return texto.length > lng ? texto.substring(0, (lng - 3)) + "..." : texto
  }

  generarcodigo(cad: number) {
    let abc = '1234567890abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let codigo = "";
    for (let i = 0; i < cad; i++) {
      codigo += abc[Math.floor(Math.random() * abc.length)]
    }
    return codigo;
  }

  actualYear() {
    const fecha = new Date();
    return fecha.getFullYear();
  }

  nombreMes(n: number) {
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    return meses[n];
  }

  textoFecha(fecha: Date, textoAnt: string = "", separador: string = " ") { 
    fecha.setTime(fecha.getTime() + fecha.getTimezoneOffset() * 60 * 1000);
    let texto = textoAnt + this.mayorDiez(fecha.getDate()) + separador + this.nombreMes(fecha.getMonth()) + separador + fecha.getFullYear() + " ";
    return texto;
  }

  mayorDiez(n: number) {
    let texto = (n < 10) ? "0" + n : n;
    return texto;
  }


  extraerprimernombre(nombre: string) {
    if (nombre) {
      let nombres = nombre.split(" ")[0];
      let primero = nombres.charAt(0).toUpperCase() + nombres.slice(1).toLowerCase();
      return primero;
    }
  }

}
